import './App.css';
import Mastheader from './Mastheader';
import Footer from './Footer';
import Theme from '@amzn/meridian/theme';
import AppLayout from '@amzn/meridian/app-layout';
import blueLightTokens from '@amzn/meridian-tokens/theme/blue-light';
import "@amzn/meridian-tokens/base/font/amazon-ember.css"
import Components from './Components';
import SignIn from './SignIn';
import { Auth, Hub } from 'aws-amplify';
import { useState, useEffect } from 'react';




function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          getUser().then((userData) => setUser(userData));
          break;
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          break;
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log('Not signed in'));
  }

  return (
    <>
      {user ? (
    

<Theme tokens={blueLightTokens}>
          <AppLayout
            headerComponent={Mastheader}
            footerComponent={Footer}
            spacingInset='small small'
          >
            <Mastheader />
            <Footer />
            <Components/>
          </AppLayout>
        </Theme>
      ) : (
      <SignIn />
      )}
    </>
  );
}

export default App;